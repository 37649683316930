import HTMLElementShim from './ce-shim'

const template = document.createElement('template')
template.innerHTML = `<div aria-controls="#idAriaControls"><slot name="trigger"></slot></div>
                          <div class="details" id="#idAriaControls"><slot></slot></div>`

class AccordionBehaviour extends HTMLElementShim {
  static get observedAttributes () { return ['open'] }

  get open () {
    return this.hasAttribute('open')
  }

  set open (val) {
    if (val) {
      this.setAttribute('open', 'true')
      this.handleResize()
    } else {
      this.handleResize()
      this.removeAttribute('open')
    }
  }

  constructor () {
    super()

    const randomId = Math.ceil(Math.random() * 10000000000)

    const shadowRoot = this.attachShadow({ mode: 'closed' })
    const style = document.createElement('style')
    style.textContent = `
                .details {
                    transition: max-height 350ms ease-in-out;
                    overflow: hidden;
                }

                .details.closed {
                    max-height: 0px !important;
                }
            `
    shadowRoot.appendChild(style)
    shadowRoot.appendChild(template.content.cloneNode(true))
    shadowRoot.innerHTML = shadowRoot.innerHTML.replace(new RegExp('#idAriaControls', 'g'), randomId)

    this.trigger = shadowRoot.querySelector('[aria-controls]')
    this.content = shadowRoot.getElementById(randomId)
    this.boundClickHandler = AccordionBehaviour.prototype.handleClick.bind(this)
    this.boundResizeHandler = AccordionBehaviour.prototype.handleResize.bind(this)
  }

  connectedCallback () {
    this.trigger.addEventListener('click', this.boundClickHandler)
    addEventListener('resize', this.boundResizeHandler)
    this.applyState()
  }

  disconnectedCallback () {
    this.trigger.removeEventListener('click', this.boundClickHandler)
    removeEventListener('resize', this.boundResizeHandler)
  }

  handleClick () {
    this.open = !this.open
    this.applyState()
  }

  handleResize () {
    this.content.style.maxHeight = this.content.scrollHeight + 'px'
  }

  applyState () {
    this.content.classList.toggle('closed', !this.open)
    this.trigger.setAttribute('aria-expanded', this.open.toString())
    this.content.firstChild.setAttribute('tabindex', this.open ? '0' : '-1') // content has a single slot child
  }
}

customElements.define('hb-accordion', AccordionBehaviour)
